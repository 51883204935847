:root {
  --main-yellow: #dfb947;
  --dark-grey: #2d2d2d;
  --light-grey: #969696;
}

@font-face {
  font-family: Nunito;
  src: url("assets/fonts/Nunito-Regular.ttf");
}

@font-face {
  font-family: Nunito-sb;
  src: url("assets/fonts/Nunito-SemiBold.ttf");
}

@font-face {
  font-family: Nunito-b;
  src: url("assets/fonts/Nunito-Bold.ttf");
}



/* ============================================
    GENERAL
=============================================== */
* {
  font-size: 14px;
  font-family: Nunito;
  line-height: initial;
}

.text-sb {
  font-family: Nunito-sb;
}

.text-b,
b {
  font-family: Nunito-b;
}

.content-div {
  padding: 70px 80px;
}

img {
  width: 100%;
  height: auto;
}

p {
  margin: 0;
}

.general-btn {
  border: 0;
  background: var(--dark-grey);
  color: #fff;
  padding: 16px 36px;
  font-size: 11.5px;
  letter-spacing: 1.2px;
  transition: 0.3s;
}

.general-btn:hover {
  background: #fff;
  color: var(--dark-grey) !important;
}



/* ============================================
    RESPONSIVENESS
=============================================== */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .content-div {
    padding: 30px 10px !important;
  }
}
